<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="d-flex">
            <v-img
              lazy-src="img/material/to-do-list.svg"
              max-height="100%"
              max-width="5%"
              contain
              src="img/material/to-do-list.svg"
            >
            </v-img>
            <v-card-text class="title font-weight-light ml-5">
              <span class="font-weight-bold">Задание:</span> При помощи мыши перенесите в поле "Алгоритм действий" правильные варианты действий при кровотечении и расположите их в верной последовательности.
            </v-card-text>
          </div>
        </v-col>
          <v-col cols="5">
          <v-card-title>
            Кровотечение
          </v-card-title>
          <v-card flat>
            <v-card flat class="ma-3 pa-5 d-flex justify-center">
              <v-img
                lazy-src="img/material/blood-w.svg"
                max-width="90%"
                src="img/material/blood-w.svg"
                class="ma-1"
                contain
              ></v-img>
            </v-card>
          </v-card>

        </v-col>
        <v-col cols="7">
          <div class="d-flex justify-space-between align-center">
            <v-card-title>
              Алгоритм действий
            </v-card-title>
            <div :class="resultTextColor" class="title d-flex justify-center"><v-icon :color="resultColor" class="mr-2">{{ resultIcon }}</v-icon>{{ resultMessage }}</div>
            <div v-if="toggleBtn">
              <v-btn class="mr-2" color="green" dark @click="check">
                проверить
              </v-btn>
              <v-btn :disabled="disabledNext" to="/workshop6-6">
              Далее
                <v-icon class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-btn class="mr-5" color="orange" dark @click="newTry">
                повторить
              </v-btn>
              <v-btn to="/workshop6-6">
                Далее
                <v-icon class="ml-2">mdi-arrow-right</v-icon>
              </v-btn>
            </div>

          </div>
          <v-col cols="12">
            <v-card class="pt-5 px-5 pb-1">
              <draggable class="list-group " :list="list1" :group="stopDrag">
                <div v-if="list1.length === 0">
                  <v-card-text  class="title font-weight-light pb-7 text-center">{{ message }}</v-card-text>
                </div>
                  <div
                    :class="itemColor"
                    v-else
                    class="list-group-item mb-5 mb-5 pa-3 title font-weight-light lighten-3"
                    v-for="(element, index) in list1"
                    :key="index"
                  >
                    {{ index + 1}}. {{ element.name }}
                  </div>
              </draggable>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card-title>
              Варианты действий
            </v-card-title>
              <v-card class="pa-5">
                <draggable class="list-group" :list="list2" group="no">
                  <div
                    class="list-group-item mb-2 pa-3 title font-weight-light grey lighten-3"
                    v-for="(element, index) in list2"
                    :key="index"
                    width="100%"
                  >
                    {{ element.name }}
                  </div>
                </draggable>
              </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  data: () => ({
    disabledNext: true,
    tab1: null,
    name: 'simple',
    display: 'Simple',
    order: 0,
    enabled: true,
    itemColor: 'grey',
    result: false,
    resultColor: '',
    resultTextColor: '',
    resultIcon: '',
    resultMessage: '',
    stopDrag: 'no',
    toggleBtn: true,
    message: 'Перенесите в это поле правильные варианты и расположите в верной последовательности',
    currentList: [],
    listTrue: [
      { name: 'Обеспечить безопасные условия для оказания первой помощи', id: 8 },
      { name: 'Убедиться в наличии признаков жизни у пострадавшего', id: 3 },
      { name: 'Определить вид кровотечения', id: 4 },
      { name: 'Остановить кровотечение', id: 1 },
      { name: 'Транспортировать пострадавшего в медицинское учреждение', id: 1 },
      { name: 'Немедленно доставить пострадавшего в медицинское учреждение', id: 5 }
    ],
    list2: [
      { name: 'Немедленно доставить пострадавшего в медицинское учреждение', id: 5 },
      { name: 'Убедиться в наличии признаков жизни у пострадавшего', id: 3 },
      { name: 'Обеспечить безопасные условия для оказания первой помощи', id: 8 },
      { name: 'Остановить кровотечение', id: 1 },
      { name: 'Промыть рану', id: 0 },
      { name: 'Определить вид кровотечения', id: 4 },
      { name: 'Извлечь из раны мелкие предметы', id: 6 },
      { name: 'Удалить сгустки крови и тромбы', id: 7 }
    ],
    list1: [
    ],
    dragging: false
  }),
  methods: {
    check: function () {
      const checkRes = JSON.stringify(this.list1) === JSON.stringify(this.listTrue)
      if (checkRes) {
        console.log('Верно!')
        this.itemColor = 'green'
        this.resultTextColor = 'green--text'
        this.resultColor = 'green'
        this.resultIcon = 'mdi-check'
        this.resultMessage = 'Верно!'
        this.stopDrag = 'yes'
        this.toggleBtn = false
        this.disabledNext = false
        // setTimeout(() => {
        //   this.result = true
        // }, 700)
      } else if (this.list1.length > 0 && checkRes === false) {
        this.itemColor = 'red'
        this.resultTextColor = 'red--text'
        this.resultColor = 'red'
        this.resultIcon = 'mdi-close'
        this.resultMessage = 'Не верно!'
        this.stopDrag = 'yes'
        this.toggleBtn = false
        this.disabledNext = false
        // setTimeout(() => {
        //   this.result = true
        // }, 700)
      } else {
        this.message = 'Вы не перенесли ни одного варианта'
      }
    },
    newTry: function () {
      this.$router.go(this.$router.currentRoute)
      // this.forceUpdate()
    }
  },
  computed: {
    draggingInfo () {
      return this.dragging ? 'under drag' : ''
    },
    dragOptions () {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  components: {
    draggable
  }
}
</script>
